#usefull_link_block li#orderbyrefcsv_block_extra_add {
	cursor: pointer;
}
#usefull_link_block li#orderbyrefcsv_block_extra_add a:before {
	content: "\f061";
	font-family: "FontAwesome";
	font-size: 17px;
	width: 100%;
}

span.res { display:none;color:white!important; }
tfoot input[type=submit] {
	float: right;
	display: block;
	padding: 6px 8px;
	font: bold 10px Arial,Verdana,sans-serif;
	color: #fff;
	text-shadow: 0 -1px 0 #000;
	text-transform: uppercase;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	background: url(../img/bloc_contact_a_bg.png) repeat-x 0 0 #000;
}

table.std td, table.table_block td {
	padding: 5px 5px 5px 15px;
	border-right: 1px solid #e9e9e9;
	border-bottom: 1px solid #e9e9e9;
	font-size: 12px;
	vertical-align: top;
}

#csvresponse {
	position: fixed;
	top:0;left:0;right:0;
	z-index: 99999;
}